import { graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { FullPreferenceDetails } from 'components/CheckDetails/TieredCheckDetailsPage';
import PaymentLoadingPage from 'components/LoadingPages/PaymentLoading';
import { usePageTracking } from 'helpers/usePageTracking';
import withPolicyNumber, { PolicyNumberProps } from 'helpers/withPolicyNumber';
import { CsMeta } from 'types/contentStack';

type PaymentLoadingProps = {
  data: {
    csInterstitialPage: {
      check_details_payment: {
        meta: CsMeta;
        heading: string;
        subheading: string;
      };
    };
  };
  location: Location & {
    state: {
      fullPreferences: FullPreferenceDetails;
      hasAccount: boolean | null;
      userLoggedIn: boolean;
    };
  };
};

export const query = graphql`
  query {
    csInterstitialPage {
      check_details_payment {
        meta {
          meta_title
        }
        heading
        subheading
      }
    }
  }
`;

const PaymentLoading: React.FC<PaymentLoadingProps & PolicyNumberProps> = ({
  data: {
    csInterstitialPage: { check_details_payment },
  },
  location,
}) => {
  const [fullPreferences, setFullPreferences] = useState<FullPreferenceDetails>();
  const [hasAccount, setHasAccount] = useState<boolean | null>();
  const [userLoggedIn, setUserLoggedIn] = useState<boolean>();

  useEffect(() => {
    if (location.state.fullPreferences) {
      setFullPreferences(location.state.fullPreferences);
    }
    if (location.state.hasAccount) {
      setHasAccount(location.state.hasAccount);
    }
    if (location.state.userLoggedIn) {
      setUserLoggedIn(location.state.userLoggedIn);
    }
  }, [location]);

  usePageTracking(check_details_payment.meta.meta_title);

  return (
    <PaymentLoadingPage
      paymentLoadingContent={check_details_payment}
      userLoggedIn={userLoggedIn}
      fullPreferences={fullPreferences}
      hasAccount={hasAccount}
    />
  );
};

export default withPolicyNumber(PaymentLoading);
